function Kortly() {
   return (
      <div className='background-app'>
         <div className='background-image'></div>
         <div className="App" id='parent-control'>

         </div>
      </div>
   );
}

export default Kortly;