import React from 'react';
import './Imprint.css';

const lang = ["Die Nutzung erfolgt ohne Angabe von personenbezogenen Daten. Es werden keine persönlichen Daten erhoben oder gesammelt. Diese Website verwendet keine externen Dienste wie Google Analytics, Matomo oder Social-Media Tracker. Links die auf Social-Media Profile verlinken tracken nicht und erfassen auch keine Daten. Bitte beachten Sie, dass der Datenschutz der verlinkten Webseiten voneinander abweichen kann. Es wird technisch bedingt die IP-Adresse des verbundenen Geräts verarbeitet und durch den Webserver für 14 Tage in einer Log-Datei gespeichert.",
  "There is no personal data collection on this website. This website does not use any external services such as Google Analytics, Matomo or social media trackers. Please note that the data protection of the linked websites may differ. For technical reasons, the IP address of the connected device is processed and stored in a log file by the web server for 14 days."];
const imp = ["Impressum", "Imprint"];
const tmg = ["Angaben gemäß $ 5 TMG", "By german law $ 5 TMG"];
const datprot = ["Datenschutz", "Data protection"];

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function Imprint() {
  let divlang = lang[1];
  let impr = imp[1];
  let law = tmg[1];
  let data = datprot[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    divlang = lang[0];
    impr = imp[0];
    law = tmg[0];
    data = datprot[0];
  }
  return (
    <div id='imprint' className="cursor-pointer mt-4" >
      <div id='outer' className='Imprint-Inner cursor-default'>
        <div id='inner' className='relative bg-zinc-800/50 p-6 rounded-xl shadow-md border-2 border-zinc-900'>
          <h2>{impr}</h2>
          <a>{law}</a>
          <div>
            <p className='bold' >Florian Schneider</p>
            <a>c/o Block Services</a><br />
            <a>Stuttgarter Str. 106</a><br />
            <a>70736 Fellbach</a><br />
            <a>about@florian-s.de</a><br />
            <br />
          </div>
          <div>
            <h2>{data}</h2>
            <a className='dataprot'>
              {divlang}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Imprint;