
const certificates = ["Zertifikate", "Certificates"];

function Show(path: string) {
   let img = document.getElementById("imagefull");
   if (img == null) return;
   img.setAttribute("src", path)
}

function GetCurrentLanguage() {
   const userlang = navigator.language;
   return userlang;
}

function CertificatesPage() {
   let cert = certificates[1];
   let _lang = GetCurrentLanguage();
   if (_lang == "de" || _lang == "de-DE") {
      cert = certificates[0];
   }
   return (
      <div className='absolute h-screen w-screen'>
         <div className="flex flex-col sm:flex-row h-full">
            <div className="overflow-x-scroll sm:overflow-y-scroll sm:max-w-[15%] w-full sm:w-auto flex sm:block space-x-4 sm:space-x-0 sm:space-y-4 p-4 h-32 sm:h-auto">
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/z_sharpgrund.jpg' onClick={() => Show('/cert/z_sharpgrund.jpg')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/z_sharpaufbau.jpg' onClick={() => Show('/cert/z_sharpaufbau.jpg')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/z_pythonaufbau.jpg' onClick={() => Show('/cert/z_pythonaufbau.jpg')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/az_asp.png' onClick={() => Show('/cert/az_asp.png')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/az_aspcore.png' onClick={() => Show('/cert/az_aspcore.png')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/az_go.png' onClick={() => Show('/cert/az_go.png')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/mscsharp.jpg' onClick={() => Show('/cert/mscsharp.jpg')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/ud_csharp.jpg' onClick={() => Show('/cert/ud_csharp.jpg')} />
               <img className='h-24 sm:w-32 sm:h-auto object-contain cursor-pointer' src='/cert/ud_maui.jpg' onClick={() => Show('/cert/ud_maui.jpg')} />
            </div>
            <div className="flex-1 flex items-center justify-center mt-4 sm:mt-0">
               <img id="imagefull" className="max-h-full max-w-full object-contain" src="/cert/z_sharpgrund.jpg" />
            </div>
         </div>
      </div>
   );
}

export default CertificatesPage;