import React from 'react';
import './Education.css';

const edu = ["Bildung", "Education"];
const ess = ["Hochschule Esslingen Medieninformatik", "Esslingen university"];
const ws = ["Staatlich geprüfter Techniker Werner-Siemens Schule", "State certified technician Werner-Siemens Schule"];
const ap = ["Ausbildung Mechatroniker", "Apprenticeship mechatronics engineer"];
const we = ["Arbeitserfahrung", "Working Experience"];
const ic = ["Informationstechniker Sensordaten", "Informationtechnician sensorcontrol and -visualizing"];
const se = ["HiWi Softwareentwicklung", "Software engineer Res. Asst."];
const sp = ["Schuler Pressen GmbH Mechatroniker", "Schuler Pressen GmbH Mechatronic"];
const now = ["jetzt", "now"]

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function Education() {
  let edstr = edu[1];
  let essstr = ess[1];
  let wsstr = ws[1];
  let apstr = ap[1];
  let work = we[1];
  let icstr = ic[1];
  let sestr = se[1];
  let spstr = sp[1];
  let nowstr = now[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    edstr = edu[0];
    essstr = ess[0];
    wsstr = ws[0];
    apstr = ap[0];
    work = we[0];
    icstr = ic[0];
    sestr = se[0];
    spstr = sp[0];
    nowstr = now[0];
  }
  return (
    <div className='Education-Total'>
      <div className="Education">
        <table className='Table'>
          <tbody>
            <tr>
              <td colSpan={2} className='Header'>{work}</td>
            </tr>
            <tr>
              <td className='Date text-sm'>Sep 2022 - Jun 2023</td>
              <td className='Date text-base'>{icstr}</td>
            </tr>
            <tr>
              <td className='Date text-sm'>Okt 2021 - Sep 2022</td>
              <td className='Date text-base'>{sestr}</td>
            </tr>
            <tr>
              <td className='Date text-sm'>Feb 2017 - Aug 2018</td>
              <td className='Date text-base'>{spstr}</td>
            </tr>
            <tr>
              <td colSpan={2} className='Header'>{edstr}</td>
            </tr>
            <tr>
              <td className='Date text-sm'>Sep 2020 - Jun 2024</td>
              <td className='Date text-base'>{essstr}</td>
            </tr>
            <tr>
              <td className='Date text-sm'>Sep 2018 - Jun 2020</td>
              <td className='Date text-base'>{wsstr}</td>
            </tr>
            <tr>
              <td className='Date text-sm'>Sep 2015 - Feb 2017</td>
              <td className='Date text-base'>{apstr}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Education;