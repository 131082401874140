import React from 'react';
import Imprint from './imprint';
import './Footer.css';
import { Link } from 'react-router-dom';

function Show() {
  let element = document.getElementById("imprint");
  element?.classList.add("Imprint-show");
}

function Footer({ data }: any) {
  return (
    <div>
      <div className="Footer z-10 bg-zinc-800">
        <div>
          <a className='flex justify-start pl-4'>Florian Schneider {new Date().getFullYear()}</a>
        </div>
        <div onClick={Show} className='cursor-pointer'>
          <Link to={"/imprint"}>
            <a className='flex justify-end pr-4'>Impressum</a>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;