import Imprint from './imprint';
import AboutMe from './AboutMe';
import MyProjects from './MyProjects';
import Spacer from './Spacer';
import Footer from './Footer';
import Socials from './Socials';
import Skills from './Skills';
import Certificates from './Certificates';
import OtherSkills from './OtherSkills';
import Education from './Education';
import ImageFullScreen from './ImageFullScreen';
import Hobbies from './Hobbies';
import Panel from './Panel';
import { Link } from 'react-router-dom';
import ProjectPanel from './ProjectPanel';

const project = ["Projekte", "Projects"];
const inter = ["Interessen", "Interests"];

function GetCurrentLanguage() {
   const userlang = navigator.language;
   return userlang;
}

function Main() {
   let hobbies = project[1];
   let interests = inter[1];
   let _lang = GetCurrentLanguage();
   if (_lang == "de" || _lang == "de-DE") {
      hobbies = project[0];
      interests = inter[0];
   }
   return (
      <div className='background-app'>
         <div className='background-image'></div>
         <div className="App" id='parent-control'>
            <ImageFullScreen />
            <AboutMe />
            <Spacer />
            <Socials />
            <Spacer />
            <Skills />
            <OtherSkills />
            <Spacer />
            <Education />
            <Spacer />
            <div className='container-hobbies'>
               <a className='Header'>{hobbies}</a>
               <ProjectPanel redirect={"projects"} />
            </div>

            <Spacer />
            <div className='container-hobbies'>
               <a className='Header'>{interests}</a>
               <Panel redirect={"interests"} />
            </div>
            <Spacer />
            <Certificates />
            <Footer />
         </div>
      </div>
   );
}

export default Main;