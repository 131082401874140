import './AboutMe.css';

const hello = ["Hallo, ich bin", "Hello, I'm"];
const role = ["Softwareentwickler, aus Stuttgart/Deutschland", "Software engineer, based in Stuttgart/Germany"]
const more = ["Erfahre unten mehr über mich", "Find more about me below"];
const contact = ["oder kontaktiere mich: ", "or contact me: "];

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function AboutMe() {
  let hellostr = hello[1];
  let roles = role[1];
  let morestr = more[1];
  let cont = contact[1]
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    hellostr = hello[0];
    roles = role[0];
    morestr = more[0];
    cont = contact[0];
  }
  return (
    <div className="AboutMe relative" >
      <div className='Default'>
        <img src='/img/me.webp' alt='Me' className='absolute z-0' />
      </div>
      <div className='Content z-10 py-4'>
        <div className='Text-Content'>
          {hellostr}<br />
          <a className='Text-Special'>Florian Schneider</a><br />
          <a className='Text-Small'></a>{roles}<br />
          <a className='Text-Small'>{morestr}</a> <br />
          <a className='Text-Small'>{cont}</a>
          <a className='Text-Small' href='mailto:me@florian-s.de'>me@florian-s.de</a>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;