import React from 'react';
import './Socials.css';
import '../App.css'

const socials = ["Meine Sozialen Medien", "My social media profiles"];

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function Socials() {
  let str = socials[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    str = socials[0];
  }
  return (
    <div className='Socials-Total'>
      <a className='Header'>{str}</a><br />
      <div className="Socials">
        <a href='https://github.com/Flo96S' target='_blank' className='h-12 w-12'><img className='rotimg object-scale-down' src='/img/git.png' /></a>
        <a href='https://linkedin.com/in/florianschneiderit/' target='_blank' className='h-12 w-12'><img className='rotimg object-scale-down' src='/img/lin.png' /></a>
        <a href='https://play.google.com/store/apps/dev?id=8690398153826603807' target='_blank' className='h-12 w-12'><img className='rotimg object-scale-down' src='/img/pstore.png' /></a>
      </div>
    </div>
  );
}

export default Socials;