import React from 'react';
import './OtherSkills.css';
import '../App.css'

const software = ["Andere Softwarefähigkeiten", "Other software skills"];

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function GoToWebsite(url: string) {
  window.open(url, '_blank');
}

function OtherSkills() {
  let str = software[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    str = software[0];
  }
  return (
    <div className='OtherSkills-Header'>
      <a className='Header'>{str}</a>
      <div className="OtherSkills">
        <div className="Skills">
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://office.com")} alt='Office' src='/software/of.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://serif.com")} alt='Serif Affinity Suite' src='/software/af.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://www.figma.com/about/")} alt='Figma' src='/software/fi.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://www.maxon.net/cinema-4d")} alt='Cinema4d' src='/software/cd.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://blender.org")} alt='Blender' src='/software/blender.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://www.blackmagicdesign.com/products/davinciresolve")} alt='Davinci Resolve' src='/software/dvr.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://www.unrealengine.com")} alt='Unreal Engine' src='/software/ue.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://godotengine.org/")} alt='Godot' src='/software/godot.png' />
          <img className='rotimg w-12 h-12 p-3 cursor-pointer' onClick={() => GoToWebsite("https://www.adobe.com/products/substance3d/apps/painter.html")} alt='Substance Painter' src='/software/sp.png' />
        </div>
      </div>
    </div>
  );
}

export default OtherSkills;