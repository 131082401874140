import { Link } from "react-router-dom";
import './Panel.css';

function Panel({ bg, bgcolor, redirect }: any) {
   return (
      <Link to={redirect}>
         <div className="panel panel-hobbies">

         </div >
      </Link>
   );
}

export default Panel;