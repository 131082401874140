import React from 'react';
import './Certificates.css';

const certificates = ["Zertifikate", "Certificates"];

function Show(path: string) {
  let element = document.getElementById("fsimg");
  element?.classList.add("visible");
  let img = document.getElementById("bigimage") as HTMLImageElement;
  img.src = path;
}

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function Certificates() {
  let cert = certificates[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    cert = certificates[0];
  }
  return (
    <div className='Certificates-Total'>
      <a className='Header'>{cert}</a>
      <div className="Certificates">
        <img className='cert-img' src='/cert/z_sharpgrund.jpg' onClick={() => Show('/cert/z_sharpgrund.jpg')} />
        <img className='cert-img' src='/cert/z_sharpaufbau.jpg' onClick={() => Show('/cert/z_sharpaufbau.jpg')} />
        <img className='cert-img' src='/cert/z_pythonaufbau.jpg' onClick={() => Show('/cert/z_pythonaufbau.jpg')} />
        <img className='cert-img' src='/cert/az_asp.png' onClick={() => Show('/cert/az_asp.png')} />
        <img className='cert-img' src='/cert/az_aspcore.png' onClick={() => Show('/cert/az_aspcore.png')} />
        <img className='cert-img' src='/cert/az_go.png' onClick={() => Show('/cert/az_go.png')} />
        <img className='cert-img' src='/cert/mscsharp.jpg' onClick={() => Show('/cert/mscsharp.jpg')} />
        <img className='cert-img' src='/cert/ud_csharp.jpg' onClick={() => Show('/cert/ud_csharp.jpg')} />
        <img className='cert-img' src='/cert/ud_maui.jpg' onClick={() => Show('/cert/ud_maui.jpg')} />
      </div>
    </div>
  );
}

export default Certificates;