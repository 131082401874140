import './App.css';
import React from 'react';
import Cookie from './elements/cookies';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './elements/Main';
import AboutMe from './elements/AboutMe';
import ProjectPage from './pages/ProjectPage';
import Layout from './pages/Layout';
import Hobbies from './elements/Hobbies';
import { Certificate } from 'crypto';
import Certificates from './elements/Certificates';
import Imprint from './elements/imprint';
import Vacatee from './pages/ProjectsLayout/Vacatee';
import Ethical from './pages/ProjectsLayout/Ethical';
import Kortly from './pages/ProjectsLayout/Kortly';
import QrScanner from './pages/ProjectsLayout/QrScanner';
import MediaCyberMentor from './pages/ProjectsLayout/MediaCyberMentor';
import StayFocused from './pages/ProjectsLayout/StayFocused';
import Questboard from './pages/ProjectsLayout/Questboard';
import Opinyon from './pages/ProjectsLayout/Opinyon';
import ApiObserver from './pages/ProjectsLayout/ApiObserver';
import NewProjects from './pages/NewProjects';
import FourOFour from './pages/404';
import Footer from './elements/Footer';
import CertificatesPage from './pages/CertificatePage';


function App() {
  return (
    <div className='background-app'>
      <div className='background-image'></div>
      <div className="App" id='parent-control'>
        <Routes>
          <Route index element={<Main />} />
          <Route path="projects" element={<ProjectPage />} />
          <Route path="newprojects" element={<NewProjects />} />
          <Route path="projects/vacatee" element={<Vacatee />} />
          <Route path="projects/ethical" element={<Ethical />} />
          <Route path="projects/kortly" element={<Kortly />} />
          <Route path="projects/qrscanner" element={<QrScanner />} />
          <Route path="projects/mediacybermentor" element={<MediaCyberMentor />} />
          <Route path="projects/stayfocused" element={<StayFocused />} />
          <Route path="projects/questboard" element={<Questboard />} />
          <Route path="projects/opinyon" element={<Opinyon />} />
          <Route path="projects/apiobserver" element={<ApiObserver />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="interests" element={<Hobbies />} />
          <Route path="cert" element={<CertificatesPage />} />
          <Route path='*' element={<FourOFour />} />
        </Routes>
      </div>
    </div >
  );
}

export default App;
