import React, { useState, useEffect } from 'react';

let url = "/img/gioplaystore.png";

const TextFileComponent = ({ fileNumber, link, apiurl }: any) => {
   const [fileContent, setFileContent] = useState('');
   useEffect(() => {
      const fetchTextFile = async () => {
         try {
            const response = await fetch("/Projects/" + fileNumber + "_" + lang + ".txt");
            const content = await response.text();
            setFileContent(content);
         } catch (error) {

         }
      }
      fetchTextFile();
   }, [fileNumber, url]);

   function CheckUrl(apiurl: string, filenumber: number = fileNumber) {
      let state = document.getElementById('state' + filenumber);
      if (apiurl == "") {
         state?.classList.add("bg-blue-500");
         return;
      }
      if (apiurl.includes("error")) {
         state?.classList.add("bg-red-500");
         return;
      }
      try {
         fetch(apiurl, {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            },
         })
            .then(response => {
               if (response.ok) {
                  state?.classList.add("bg-green-500");
                  return "";
               }
               return "";

            }).catch(error => {
               state?.classList.add("bg-red-500");
            });
      }
      catch {
         state?.classList.add("bg-red-500");
      }
   }
   CheckUrl(apiurl);
   url = link;
   const userlang = navigator.language;
   let lang = userlang;
   if (userlang == "de-DE") {
      lang = "de";
   }
   if (userlang != "de" && userlang != "de-DE") {
      lang = "en"
   }

   let files = fileContent.split('\n');
   return (
      <div className='Project-Total relative'>
         <div id={'state' + fileNumber} className={'w-3 h-3 rounded-full absolute right-2 top-2'}></div>
         <div className='Project md:pb-8'>
            <div className='Project-Img'>
               <img className='p-img' src={files[0]} alt='Logo' />
            </div>
            <div className='Project-Text'>
               <a className='Project-Header underline'>{files[1]}</a><br />
               <a id='body' className='Project-Body'>{files[2]}</a><br />
               <div className="mobile">
                  <a className='Project-Footer'>{files[3]}</a>
               </div>
            </div>
         </div>
         <a className='playstorebtn relative right-4 bottom-0 pr-2' href={files[4]} target='_blank'>
            <img className='h-5' src={url} alt='download' />
         </a>
      </div>
   );
}
export default TextFileComponent;