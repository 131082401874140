import { Link } from "react-router-dom";
import './Panel.css';

function ProjectPanel({ bg, bgcolor, redirect }: any) {
   return (
      <Link to={redirect}>
         <div className="panel panel-projects">

         </div >
      </Link>
   );
}

export default ProjectPanel;