import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const text = ["Du bist verloren gegangen. Hier kommst du zurück.", "You look lost. Here you go back."];

function Show(path: string) {
   let element = document.getElementById("fsimg");
   element?.classList.add("visible");
   let img = document.getElementById("bigimage") as HTMLImageElement;
   img.src = path;
}

function GetCurrentLanguage() {
   const userlang = navigator.language;
   return userlang;
}

function FourOFour() {
   let cert = text[1];
   let _lang = GetCurrentLanguage();
   if (_lang == "de" || _lang == "de-DE") {
      cert = text[0];
   }
   return (
      <div className='w-full h-screen flex fourbg'>
         <Link className='md:text-2xl underline mx-auto my-auto' to={"/"}>
            <div className='bg-zinc-800/50 px-8 py-4 rounded-full backdrop-blur-sm border-zinc-800 border-2 mx-4 hover:backdrop-blur-xl hover:drop-shadow-xl'>
               <a className='md:text-xl text-sm'>{cert}</a>
            </div>
         </Link>
      </div>
   );
}

export default FourOFour;