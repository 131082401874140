import React from 'react';
import './Hobbies.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const proj = ["Interessen", "Interests"];
const foto = ["Fotografieren", "Photography"];
const prog = ["Programmieren", "Programming"];
const ai = ["KI", "AI"];
const dice = ["Würfelerkennung", "Dice detection"];
const traffic = ["Verkehrsschildererkennung", "Traffic signs recognizion"];
const chatbot = ["Chatbot mit Llama 3", "Chat bot powered by Llama 3"];
const projects = ["Zu den Projekten", "To the projects"];

function GetCurrentLanguage() {
   const userlang = navigator.language || navigator.languages[0];
   return userlang;
}

function Hobbies() {
   let lang = proj[1];
   let fot = foto[1];
   let pro = prog[1];
   let a = ai[1];
   let dic = dice[1];
   let traf = traffic[1];
   let chatb = chatbot[1];
   let project = projects[1];
   let _lang = GetCurrentLanguage();
   if (_lang == "de" || _lang == "de-DE") {
      lang = proj[0];
      fot = foto[0];
      pro = prog[0];
      a = ai[0];
      dic = dice[0];
      traf = traffic[0];
      chatb = chatbot[0];
      project = projects[0];
   }
   return (
      <div className="min-h-screen flex flex-col">
         <Link to={"/"}>
            <div className='left-2 top-2 w-10 h-10 bg-zinc-800 absolute rounded-full'>
               <a className='text-3xl'>&#8249;</a>
            </div>
         </Link>
         <div className='mt-4 flex-1 pb-4'>
            <h1 className='text-2xl'>{lang}</h1>
            <div className='mt-4'>
               <h2 className='text-xl font-bold mb-2'>{fot}</h2>
               <div className='flex h-60 overflow-x-scroll'>
                  <img src='/photos/IMG_0241.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_1212.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_1251.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_1436.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_2612.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_3107.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_4323.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_4336.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_3059.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_3273.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_3985.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_8576.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_8599.webp' className='hobbiesAnimation' />
                  <img src='/photos/IMG_8021.webp' className='hobbiesAnimation' />
               </div>
               <div className='h-px w-9/12 mx-auto bg-black my-8'></div>
               <h2 className='text-xl font-bold mt-2 mb-2'>{pro}</h2>
               <div>
                  <a className='underline' href='/projects'>{project}</a>
               </div>
               <div className='h-px w-9/12 mx-auto bg-black my-8'></div>

               <h2 className='text-xl font-bold mt-2 mb-2'>{a}</h2>
               <div>
                  <div><a>{dic}</a></div>
                  <div><a className='underline' href='https://www.hackster.io/507745/ease-easy-ai-traffic-sign-evaluation-a7a5b4' target='_blank'>{traf}</a></div>
                  <div><a>{chatb}</a></div>
               </div>
            </div>
         </div >
         <Footer />
      </div >
   );
}

export default Hobbies;