import React from 'react';
import './Spacer.css';

function Spacer() {
  return (
    <div className="Spacer">
    </div>
  );
}

export default Spacer;