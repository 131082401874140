function NewProjects() {
   return (
      <div className='background-app'>
         <div className='background-image'></div>
         <div className="App" id='parent-control'>
            <div className="grid">
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
               <div> </div>
            </div>
         </div>
      </div>
   );
}

export default NewProjects;