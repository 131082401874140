import React from 'react';
import './MyProjects.css';
import './ImageFullScreen.css'

let url = "/cert/z_sharpgrund.jpg";

function Hide(){
   let element = document.getElementById("fsimg");
   element?.classList.remove("visible");
}

function ImageFullScreen() {
  return (
    <div id='fsimg' className="Fullscreen" onClick={Hide}>
      <div className='Fullscreen-Child'>
        <img id='bigimage' className='image' alt='Image' src={url}/>
      </div>
    </div>
  );
}

export default ImageFullScreen;