import React from 'react';
import './Skills.css';
import '../App.css'

const skills = ["Meine Programmierfähigkeiten", "My programming skills"];

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}


function UpdateStars(count: number) {
  let one = document.getElementById("starOne") as HTMLImageElement;
  let two = document.getElementById("starTwo") as HTMLImageElement;
  let three = document.getElementById("starThree") as HTMLImageElement;
  let four = document.getElementById("starFour") as HTMLImageElement;
  let five = document.getElementById("starFive") as HTMLImageElement;
  one.src = "/img/starempty.svg";
  two.src = "/img/starempty.svg";
  three.src = "/img/starempty.svg";
  four.src = "/img/starempty.svg";
  five.src = "/img/starempty.svg";
  if (count == -2) {
    one.src = "/img/starfull.svg";
    two.src = "/img/starfull.svg";
    three.src = "/img/starhalf.svg";
  }
  if (count == -3) {
    one.src = "/img/starfull.svg";
    two.src = "/img/starfull.svg";
    three.src = "/img/starfull.svg";
    four.src = "/img/starhalf.svg";
  }
  if (count == -4) {
    one.src = "/img/starfull.svg";
    two.src = "/img/starfull.svg";
    three.src = "/img/starfull.svg";
    four.src = "/img/starfull.svg";
    five.src = "/img/starhalf.svg";
  }
  if (count == 3) {
    one.src = "/img/starfull.svg";
    two.src = "/img/starfull.svg";
    three.src = "/img/starfull.svg";
    return;
  }
  if (count == 4) {
    one.src = "/img/starfull.svg";
    two.src = "/img/starfull.svg";
    three.src = "/img/starfull.svg";
    four.src = "/img/starfull.svg";
  }
  if (count == 5) {
    one.src = "/img/starfull.svg";
    two.src = "/img/starfull.svg";
    three.src = "/img/starfull.svg";
    four.src = "/img/starfull.svg";
    five.src = "/img/starfull.svg";
  }
  console.log("Updated");
}

function ChangeName(name: string) {
  let element = document.getElementById("programmingname") as HTMLLabelElement;
  element.innerHTML = name;
}

function Update(name: string, count: number) {
  console.log(count);
  ChangeName(name);
  UpdateStars(count);
}

function Skills() {
  let str = skills[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    str = skills[0];
  }
  return (
    <div className='Skills-Header'>
      <a className='Header'>{str}</a>
      <div className="Skills">
        <img className='rotimg w-12 h-12' onClick={() => Update("C#", -4)} onPointerEnter={() => Update("C#", -4)} alt='CSharp' src='/programming/csharp.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("Go", 4)} onPointerEnter={() => Update("Go", 4)} alt='Go' src='/programming/go.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("Python", 4)} onPointerEnter={() => Update("Python", 4)} alt='Python' src='/programming/py.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("HTML, CSS, JS, TS", 4)} onPointerEnter={() => Update("HTML, CSS, JS, TS", 4)} alt='HTML, CSS, JavaScript' src='/programming/hcj.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("Java", -3)} onPointerEnter={() => Update("Java", -3)} alt='Java' src='/programming/java.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("SQL", 3)} onPointerEnter={() => Update("SQL", 3)} alt='SQL' src='/programming/sql.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("C++", -2)} onPointerEnter={() => Update("C++", -2)} alt='C++' src='/programming/cpp.svg' />
        <img className='rotimg w-12 h-12' onClick={() => Update("C", -2)} onPointerEnter={() => Update("C", -2)} alt='C' src='/programming/c.svg' />
      </div>
      <div id='starcontainer' className='visible'>
        <div className='programmingname'>
          <a id='programmingname'>C#</a>
        </div>
        <div className='flex mx-auto justify-center visible'>
          <img id='starOne' src='/img/starfull.svg' alt='starOne' className='stars visible' />
          <img id='starTwo' src='/img/starfull.svg' alt='starTwo' className='stars visible' />
          <img id='starThree' src='/img/starfull.svg' alt='starThree' className='stars visible' />
          <img id='starFour' src='/img/starfull.svg' alt='starFour' className='stars visible' />
          <img id='starFive' src='/img/starhalf.svg' alt='starFive' className='stars visible' />
        </div>
      </div>
    </div>
  );
}

export default Skills;