import React from 'react';
import './MyProjects.css';
import SingleElement from "./SingleElement"
import MyProjectSingleProject from './MyProjectSingleProject';
import './MyProjectSingleProject.css'
import { Link } from 'react-router-dom';
import Footer from './Footer';

const proj = ["Meine Projekte", "My Projects"];
const na = ["- Aktuell nicht verfügbar", "- Currently not available"];
const av = ["- verfügbar", "- available"];
const noapi = ["- keine api benötigt", "- no required api"];

function GetCurrentLanguage() {
  const userlang = navigator.language;
  return userlang;
}

function MyProjects() {
  let lang = proj[1];
  let notav = na[1];
  let avai = av[1];
  let noap = noapi[1];
  let _lang = GetCurrentLanguage();
  if (_lang == "de" || _lang == "de-DE") {
    lang = proj[0];
    notav = na[0];
    avai = av[0];
    noap = noapi[0];
  }
  window.scrollTo(0, 0);
  return (
    <div className="mb-0 pb-0 pt-4">
      <Link to={"/"}>
        <div className='left-2 w-10 h-10 bg-zinc-800 absolute rounded-full top-2'>
          <a className='text-3xl'>&#8249;</a>
        </div>
      </Link>
      <a className='text-2xl'>{lang}</a>
      <div className='MyProjects'>
        <SingleElement fileNumber={0} link={"/img/gioplaystore.png"} apiurl={"https://api.vacatee.de"} />
        <SingleElement fileNumber={1} link={"/img/gioplaystore.png"} apiurl={"https://api.ethicaltobuy.com/error"} />
        <SingleElement fileNumber={2} link={"/img/towebsite.png"} apiurl={"https://api.kortly.eu/"} />
        <SingleElement fileNumber={3} link={"/img/gioplaystore.png"} apiurl={""} />
        <SingleElement fileNumber={4} link={"/img/gioplaystore.png"} apiurl={"https://api.mediacybermentor.com"} />
        <SingleElement fileNumber={5} link={"/img/gioplaystore.png"} apiurl={""} />
        <SingleElement fileNumber={6} link={"/img/gioplaystore.png"} apiurl={""} />
        <SingleElement fileNumber={7} link={"/img/towebsite.png"} apiurl={"https://opinyon.de/error"} />
        <SingleElement fileNumber={8} link={"/img/download.png"} apiurl={""} />
        <SingleElement fileNumber={9} link={"/img/gioplaystore.png"} apiurl={"https://pet.api.florian-s.de"} />
      </div>
      <div>
        <div className='flex justify-center flex-col ml-8'>
          <div className='flex align-middle'>
            <div>
              <div className='inline-block w-3 h-3 bg-red-500 rounded-full align-middle'></div>
            </div>
            <div className='align-middle'>
              <a className='ml-2'>{notav}</a>
            </div>
          </div>
          <div className='w-1 h-full bg-white'></div>
          <div className='flex align-middle'>
            <div>
              <div className='inline-block w-3 h-3 bg-green-500 rounded-full align-middle'></div>
            </div>
            <div>
              <a className='ml-2 align-middle'>{avai}</a>
            </div>
          </div>
          <div className='w-1 h-full bg-white'></div>
          <div className='flex align-middle'>
            <div>
              <div className='inline-block w-3 h-3 bg-blue-500 rounded-full align-middle'></div>
            </div>
            <div>
              <a className='ml-2 align-middle'>{noap}</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MyProjects;