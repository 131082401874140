import MyProjects from "../elements/MyProjects";


function ProjectPage() {
   return (
      <div className='background-app'>
         <div className='background-image'></div>
         <div className="App" id='parent-control'>
            <MyProjects />
         </div>

      </div>
   );
}

export default ProjectPage;